/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";

@import "var-styles.scss";

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  background-color: $base-bg-color !important;
}

.bg-keytocheck {
  background-color: $base-color;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 260px;
  max-width: 260px;
}

.btn-keytocheck {
  color: $base-white-color;
  background-color: $base-color;
  border: 2px solid $base-white-color;
  border-radius: 50px;
  width: 100%;
  transition: all .5s ease-in-out;

  &:hover {
    background-color: $base-white-color;
    border: 2px solid $base-white-color;
    color: $base-color;
    transition: all .5s ease-in-out;
  }
}

.text-keytocheck {
  color: $base-color;
}

.back {
  cursor: pointer;
  color: $base-text-color;
  padding: 5px 12px;
  border-radius: 30px;

  &:hover {
    color: $base-color;
    background-color: $base-light-blue-color;
  }
}

a {
  text-decoration: none !important;
  color: $base-color;
}

.cursor {
  cursor: pointer;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 3px 6px $base-box-shadow;
  border-top: 10px solid $base-color;
}

.dropdown-item {
  cursor: pointer;
  line-height: 47px;

  &:hover {
    background-color: $base-light-blue-color;
    color: $base-color;
  }

  &:active {
    background-color: $base-color;
    color: $base-white-color;
  }
}

@media (max-width: 991px) {
  .bg-keytocheck {
    max-width: 100%;
    min-width: 100%;
  }
}
