/* Importing Comfortaa font. */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

/* Importing Montserrat font. */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

@import "./styles/colors.scss";

* {
  font-family: 'Montserrat', sans-serif;
}

.text-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.text-comfortaa {
  font-family: 'Comfortaa', sans-serif;
}
