$base-color: #1451AB;
$base-white-color: #ffffff;
$base-bg-color: #f9f9f9;
$base-text-color: #1D1D1D;
$base-input-bg-color: #F4F4F4;
$base-placeholder-input-color: #D7D7D7;
$base-light-blue-color: #C8DDEC;

$base-left-gradient-color: #1451AB;
$base-right-gradient-color: #1451AB;

$base-valid-color-1: #486726;
$base-valid-color-2: #DDE6CD;

$base-warning-color-1: #F3A72E;
$base-warning-color-2: #FCF3D7;

$base-danger-color-1: #B5232A;
$base-danger-color-2: #FFDAD7;

$base-pending-color-1: #455A64;
$base-pending-color-2: #ECEFF1;

$base-box-shadow: #00000029;

$base-hover-color: #1451AB;

$base-gradient: linear-gradient(to right, $base-left-gradient-color 10%, $base-right-gradient-color 100%);

$base-border-color: #1975BB38;
$base-border-hover-color: #1975BB38;

$base-menu-color-text: $base-text-color;

$base-error-color: #DB0000;

$base-button-yellow-color: #FAF0D2;
$base-button-yellow-color-border: #FFCA3A;
